<template>
  <div>
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Chargeable Order') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'charge-table' }" >
          {{ $t('Order List') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{ $t('Create Charge') }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>

    <!-- back button -->
    <b-button
      variant="flat-secondary"
      class="ll-cancel"
      :to="{ name: 'charge-table' }"
    >
      <!-- <feather-icon icon="RotateCcwIcon" /> -->
      <b-img
        :src="require('@/assets/images/imgs/return.png')"
        height="auto"
        class="mr-1"
      />
    </b-button>

    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="formSubmitted"
    >

      <!-- Invoice Type -->
      <tab-content
        title="Invoice Type"
        :before-change="validationForm"
        class="ll-0"
      >
        <validation-observer
          ref="rules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                {{ $t('Enter Invoice Detail') }}
              </h5>
            </b-col>
            <b-col md="5">
              <b-form-group
                label=""
                label-for="customers"
              >
                <validation-provider
                  #default="{ errors }"
                  name="customers"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t('Customer') }}</label>
                  <v-select
                    id="customers"
                    :options="customerList"
                    v-model="condition.customerName"
                    @input="getCustmer"
                  >
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group
                label=""
                label-for="invoiceType"
              >
                <validation-provider
                  #default="{ errors }"
                  name="invoiceType"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t('Invoice Type') }}</label>
                  <v-select
                    id="invoiceType"
                    :options="invoiceList"
                    label="invoiceType"
                    v-model="condition.invoiceType"
                    @input="getInType"
                  >
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            class="mb-2"
            v-for="(item, index) in items"
            :id="item.id"
            :key="item.id"
            ref="row"
          >
            <b-col md="5">
              <b-form-group
                label=""
                label-for="feeType"
              >
                <label class="ll-boldText">{{ $t('Fee type') }}</label>
                <v-select
                  id="feeType"
                  :options="feeTypeList"
                  label="feeType"
                  @input="getFeeType"
                  ref="fType"
                />
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group
                label=""
                label-for="amount"
              >
                <label class="ll-boldText">{{ $t('Amount') }}(USD)</label>
                <v-select
                  id="amount"
                  :options="amountList"
                  @input="getAmount"
                  ref="amt"
                >
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="2" class="ll-middle">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0"
                @click="removeItem(index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
                <span>{{ $t('Delete') }}</span>
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
        <b-button
          variant="primary"
          @click="repeateAgain"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>{{ $t('Add New') }}</span>
        </b-button>
      </tab-content>

      <!-- Tracking Number -->
      <tab-content
        title="Tracking Number"
        :before-change="validationTkid"
        class="ll-0"
      >
        <validation-observer
          ref="tkidRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                {{ $t('Enter Tracking ID') }}
              </h5>
            </b-col>
            <b-col
              cols="4"
            >
              <v-select
                :options="feeUnitList"
                label="label"
                @input="updateFeeUnit"
                placeholder="Please Select Fee Unit"
              />
            </b-col>
            <b-col
              cols="8"
            >
              <b-form-input
                v-if="sel1"
                v-model="mawb"
                placeholder="Please Enter MawbNo"
                @input="getMawb"
              />
              <b-form-textarea
                v-if="sel2"
                v-model="tkid"
                placeholder="Please Enter TkID"
                rows="12"
                @input="getTkid"
              />
              <b-form-input
                v-if="sel3"
                v-model="refNum"
                placeholder="Please Enter Ref Number"
                @input="getRefnum"
              />
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- Preview  -->
      <tab-content
        title="Preview Of Invoice"
        :before-change="submitBtn"
        class="ll-0"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2 ll-tableBox"
          >
            <b-table :items="Pitems" :fields="Pfields"/>
          </b-col>
          <b-col
            cols="12"
            class="ll-total"
          >
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="3">{{ $t('Sub Total') }}: {{subTotal}}</b-col>
              <b-col cols="3">
                <div v-for="(item, index) of arr1" :key="index">
                  {{ $t('Fee type') }}: {{ item }}
                </div>
              </b-col>
              <b-col cols="3">
                <!-- <div v-for="(item, index) of arr2" :key="index" class="ll-aBox">
                  Amount: {{ item }}
                </div>
                <hr class="ll-hr"> -->
                <div class="ll-aBox">
                  <span>{{ $t('Total') }}:</span>
                  <span class="ll-span">{{totalA}}</span>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BTable,
  BFormInput,
  BImg,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BBreadcrumb,
    BBreadcrumbItem,
    BTable,
    BFormInput,
    BImg,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      required,
      customerList: ['AMZUS'],
      items: [{
        id: 1,
        selected: 'male',
        selected1: 'designer',
        prevHeight: 0,
      }],
      nextTodoId: 2,
      invoiceList: [],
      feeTypeList: [],
      amountList: [],
      feeUnitList: [
        { label: 'By MAWB', value: '0' },
        { label: 'By Tracking ID', value: '1' },
        // { label: 'By Ref Number', value: '2' },
      ],
      sel1: false,
      sel2: false,
      sel3: false,
      tkid: '',
      Pitems: [],
      Pfields: [
        { key: 'customerName', label: this.$t('Customer') },
        { key: 'invoiceType', label: this.$t('Invoice Type') },
        { key: 'feeType', label: this.$t('Fee type') },
        { key: 'tkID', label: this.$t('TKID') },
        { key: 'amount', label: this.$t('Amount') },
      ],
      feeType: '',
      condition: {
        type: 0,
        customerName: 'AMZUS',
        invoiceType: '',
        feeType: [],
        amount: [],
        tkNumber: [],
        operator: '',
      },
      mawb: '',
      refNum: '',
      arr1: [],
      arr2: [],
      totalA: '',
      arr3: [],
      subTotal: null,
      amount: null,
    }
  },
  computed: {},
  watch: {
  },
  mounted() {
    this.initTrHeight()
    this.queryInType()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.addEventListener('resize', this.initTrHeight)
  },
  methods: {
    queryInType() {
      this.$http.get('invoiceDetail/getInvoiceTypeList').then(res => {
        this.invoiceList = res.data.data
      })
    },
    queryFeeType() {
      this.$http.get('invoiceDetail/getFeeTypeList', { params: { invoiceType: this.condition.invoiceType } }).then(res => {
        this.feeTypeList = res.data.data
        this.arr3 = res.data.data
      })
    },
    queryAmount() {
      this.$http.get('invoiceDetail/getAmountList', { params: { invoiceType: this.condition.invoiceType, feeType: this.feeType } }).then(res => {
        this.amountList = res.data.data.amount
      })
    },
    // 获取客户名称
    getCustmer() {
      // console.log(t)
    },
    getInType(i) {
      this.condition.invoiceType = i.invoiceType
      this.queryFeeType()
    },
    getFeeType(f) {
      if (f !== null) {
        this.feeType = f.feeType
        if (f.feeType === 'UND') {
          this.amount = 150
        }
        if (this.condition.invoiceType !== '' && this.feeType !== '') {
          this.queryAmount()
        }
        const ftRef = this.$refs.fType
        const arr = []
        ftRef.map(t => {
          if (t.selectedValue.length !== 0) {
            arr.push(t.selectedValue[0])
            return t
          }
        })
        this.feeTypeList = this.feeTypeList.filter(x => !arr.includes(x))
        const arr2 = []
        arr.map(tt => {
          arr2.push(tt.feeType)
          return tt
        })
        this.condition.feeType = arr2
        // console.log(this.condition.feeType)
      } else {
        this.$http.get('invoiceDetail/getFeeTypeList', { params: { invoiceType: this.condition.invoiceType } }).then(res => {
          this.feeTypeList = res.data.data
          const ftRef = this.$refs.fType
          const arr = []
          ftRef.map(t => {
            if (t.selectedValue.length !== 0) {
              arr.push(t.selectedValue[0])
              return t
            }
          })
          this.feeTypeList = this.feeTypeList.filter((x) => !arr.some((item) => x.feeType === item.feeType))
        })
      }
    },
    getAmount() {
      const amtRef = this.$refs.amt
      const arr = []
      amtRef.map(t => {
        arr.push(t.selectedValue[0])
        return t
      })
      this.amountList = this.amountList.filter(x => !arr.includes(x))
      const arr2 = []
      arr.map(tt => {
        arr2.push(tt)
        return tt
      })
      this.condition.amount = arr2
    },
    updateFeeUnit(u) {
      this.condition.type = u.value
      switch (u.value) {
        case '0':
          this.sel1 = true
          this.sel2 = false
          this.sel3 = false
          break
        case '1':
          this.sel2 = true
          this.sel1 = false
          this.sel3 = false
          break
        default:
          this.sel3 = true
          this.sel2 = false
          this.sel1 = false
          break
      }
    },
    getMawb() {
      if (this.mawb.trim() !== '') {
        this.condition.tkNumber = this.mawb.trim().split(/[(\r\n)\r\n]+/)
      }
    },
    getTkid() {
      if (this.tkid.trim() !== '') {
        this.condition.tkNumber = this.tkid.trim().split(/[(\r\n)\r\n]+/)
      }
    },
    getRefnum() {
      if (this.refNum.trim() !== '') {
        this.condition.tkNumber = this.refNum.trim().split(/[(\r\n)\r\n]+/)
      }
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      setTimeout(() => {
        const ftRef = this.$refs.fType
        const arr = []
        ftRef.map(t => {
          arr.push(t.selectedValue[0])
          return arr
        })
        // console.log(arr)
        this.arr3 = this.arr3.filter(x => !arr.includes(x))
        // console.log(this.arr3)
        this.feeTypeList = this.arr3
      }, 1000)
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    // 提交
    formSubmitted() {
      // this.$router.push({ name: 'charge-table' })
    },
    // 验证
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.rules.validate().then(success => {
          const fee = this.condition.feeType.length
          const amt = this.condition.amount.length
          if (success && fee !== 0 && amt !== 0) {
            resolve(true)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Fee Type and Amount field is required',
                icon: 'XIcon',
                variant: 'danger',
              },
            })
            reject()
          }
        })
      })
    },
    // 新增前 列表展示
    createCharge() {
      this.condition.operator = JSON.parse(localStorage.getItem('userInfo')).username
      this.$http.post('invoiceDetail/beforeSave', this.condition).then(res => {
        // console.log(res.data)
        if (res.data.code === '200') {
          const d = res.data.data
          this.Pitems = d
          const arrT = []
          const arrA = []
          d.map(t => {
            arrT.push(t.feeType)
            arrA.push(t.amount)
            return t
          })
          Array.from(new Set(arrT))
          this.arr1 = [...new Set(arrT)]
          this.arr2 = arrA.map(Number)
          if (arrA.length === 0) {
            this.totalA = ''
          } else if (arrA.length === 1) {
            const temp = this.arr2[0]
            this.totalA = temp
          } else {
            this.arr2.reduce((a, b) => {
              this.totalA = a + b
              return a + b
            })
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      }).catch(error => console.log(error))
    },
    // 校验tkNumber 不能为空
    validationTkid() {
      return new Promise((resolve, reject) => {
        this.$refs.tkidRules.validate().then(success => {
          if (success) {
            if (this.condition.tkNumber.length !== 0) {
              // this.createCharge()
              this.condition.operator = JSON.parse(localStorage.getItem('userInfo')).username
              this.$http.post('invoiceDetail/beforeSave?isSave=false', this.condition).then(res => {
                // console.log(res.data)
                if (res.data.code === '200') {
                  const d = res.data.data
                  this.subTotal = d.length
                  this.Pitems = d
                  const arrT = []
                  const arrA = []
                  d.map(t => {
                    arrT.push(t.feeType)
                    arrA.push(t.amount)
                    return t
                  })
                  Array.from(new Set(arrT))
                  this.arr1 = [...new Set(arrT)]
                  this.arr2 = arrA.map(Number)
                  if (arrA.length === 0) {
                    this.totalA = ''
                  } else if (arrA.length === 1) {
                    const temp = this.arr2[0]
                    this.totalA = temp
                  } else {
                    this.arr2.reduce((a, b) => {
                      this.totalA = a + b
                      return a + b
                    })
                  }
                  // 保留两位小数点
                  this.totalA = this.totalA.toFixed(2)
                  resolve(true)
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: res.data.message,
                      icon: 'XIcon',
                      variant: 'danger',
                    },
                  })
                  reject()
                }
              }).catch(error => console.log(error))
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Tracking Number field is required',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              })
              reject()
            }
          } else {
            reject()
          }
        })
      })
    },
    // 添加

    // 点击提交跳转
    submitBtn() {
      return new Promise((resolve, reject) => {
        this.$refs.rules.validate().then(success => {
          if (success) {
            this.$http.post('invoiceDetail/beforeSave?isSave=true', this.condition).then(res => {
              if (res.data.code === '200') {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Create Charge Success',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                this.$router.push({ name: 'charge-table' })
                resolve(true)
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Create Charge Fail',
                    icon: 'XIcon',
                    variant: 'danger',
                  },
                })
              }
            })
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style scoped>
.ll-0{
  min-height: 300px;
}
.ll-total{
  text-align: center;
}
.ll-hr{
  width: 50%;
}
.ll-aBox{
  width: 150px;
  margin: auto;
  text-align: left;
}
.ll-span{
  font-weight: bold;
  padding: 10px;
}
.ll-middle{
  margin: auto;
  margin-bottom: 1rem;
}
.ll-tableBox{
  height: 500px;
  overflow-y: auto;
}
</style>
<style>
[dir] .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn:hover{
  box-shadow: 0 8px 25px -8px #7367f0;
}
</style>
